.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  
}



::-webkit-scrollbar {
  width: 14px;
}
::-webkit-scrollbar-thumb {
border: 4px solid rgba(0, 0, 0, 0);
background-clip: padding-box;
border-radius: 9999px;
background-color: #AAAAAA;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgb(206, 59, 51);
}

.custom-marker {
  width: 30;
  height: 30;
  font-weight: 'bold';
  caret-color: '#f00';
  color: '#fff';
  border-radius: '50%';
  display: 'flex';
  justify-content: "center";
  align-items: "center";
};